
import notification from "@/services/notificationService";
import Vue from "vue";
import api from "../services/dataService";

export default Vue.extend({
  name: "Tenants",
  data() {
    return {
      tenants: undefined,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      tenantToEdit: undefined,
      editTenantToggle: false,
      editTenantFeaturesCheckboxGroup: []
    };
  },
  created() {
    this.getTenants();
  },
  methods: {
    getTenants() {
      api
        .get("/tenants")
        .then(res => {
          this.tenants = res.data;
        })
        .catch(err => {
          notification.error(err.message);
        });
    },
    editTenant(t: any) {
      this.tenantToEdit = t;
      this.editTenantFeaturesCheckboxGroup = t.availableFeatures;
      this.editTenantToggle = true;
    },
    updateTenant() {
      const tenantFeatures = this.editTenantFeaturesCheckboxGroup;
      // make sure atleast one option is selected
      if (!tenantFeatures || tenantFeatures?.length < 1) {
        notification.error("Atleast one option must be selected!");
        return;
      }
      // check if tenant features does not include all, then make sure that atleast basic features are selected
      if (
        !tenantFeatures?.includes("all") &&
        !tenantFeatures?.includes("basic")
      ) {
        notification.error(
          "Make sure that atleast Basic features are selected!"
        );
        return;
      }

      api
        .put(`/tenants/${this.tenantToEdit.id}/features`, tenantFeatures)
        .then(res => {
          notification.success("Tenant access updated!");
        })
        .catch(err => {
          notification.error(err.message);
        })
        .finally(() => {
          this.tenantToEdit = undefined;
          this.editTenantFeaturesCheckboxGroup = [];
          this.editTenantToggle = false;
          this.getTenants();
        });
    },
    handleEditTenantFeatureAllSelected(event: any) {
      if (event?.length > 0) {
        // check if user has selected all.
        if (event?.includes("all")) {
          // only keep all if user selects all and remove other selections
          this.editTenantFeaturesCheckboxGroup = ["all"];
        }
      }
    }
  }
});
