
import notification from "@/services/notificationService";
import Vue from "vue";
import api from "../services/dataService";

export default Vue.extend({
  name: "Orders",
  data() {
    return {
      orders: undefined,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      api
        .get("/orders")
        .then(res => {
          this.orders = res.data;
        })
        .catch(err => {
          notification.error(err.message);
        });
    },
    approveOrder(id: string) {
      api
        .patch("/orders/approve/" + id)
        .then(res => {
          notification.success("Order approved");
          this.getOrders();
        })
        .catch(err => {
          console.log(err);
          notification.error(err.message);
        });
    }
  }
});
