
import Vue from "vue";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
import router from "@/router";
// import { reject, orderBy, find } from "lodash";
import reject from "lodash/reject";
import orderBy from "lodash/orderBy";
import find from "lodash/find";
import { Job, JobStatus } from "@/shared/models";
import JobsRightSidebar from "@/components/JobsRightSidebar.vue";
import { DateTime } from "luxon";
import { filter } from "vue/types/umd";
export default Vue.extend({
  name: "Jobs",
  components: { JobsRightSidebar },
  data() {
    return {
      DateTime: DateTime,
      JobStatus,
      tableProps: {
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10
      },
      jobs: undefined as undefined | Job[],
      selected: undefined as undefined | Job,
      filteredJobs: undefined as undefined | Job[],
      addJobToggle: false,
      filters: {
        typeInProgress: false,
        typeNew: false,
        typeCompleted: false,
        searchQuery: undefined as undefined | string,
        sort: "ascending",
        category: [] as string[]
      },
      addJobDetail: {
        title: undefined,
        description: undefined,
        postcode: undefined,
        startDate: undefined,
        endDate: undefined,
        status: JobStatus.NEW,
        assignee: undefined
      }
    };
  },
  created() {
    this.getJobs();
    // setting filters if query has any filters selected
    if (this.$route.query.sort) {
      this.filters.sort = this.$route.query.sort.toString();
    }
    if (this.$route.query.search) {
      this.filters.searchQuery = this.$route.query.search.toString();
    }
  },
  methods: {
    openAddJobModal() {
      this.addJobToggle = true;
      this.removeSelection();
    },
    clearJobTypeFilter() {
      this.filters.category = [];
      this.filterJobs();
    },
    filterJobs() {
      // adding selected filters as query
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          category: this.filters.category.toString(),
          sort: this.filters.sort,
          search: this.filters.searchQuery
        }
      });

      // load jobs to filter
      this.filteredJobs = this.jobs;
      // check if any filter applied, skip if none applied
      this.filters.typeNew = false;
      this.filters.typeInProgress = false;
      this.filters.typeCompleted = false;
      for (let i = 0; i < this.filters.category.length; i++) {
        if (this.filters.category[i] === JobStatus.NEW) {
          this.filters.typeNew = true;
        }
        if (this.filters.category[i] === JobStatus.COMPLETED) {
          this.filters.typeCompleted = true;
        }
        if (this.filters.category[i] === JobStatus.IN_PROGRESS) {
          this.filters.typeInProgress = true;
        }
      }
      if (
        this.filters.typeNew ||
        this.filters.typeInProgress ||
        this.filters.typeCompleted
      ) {
        if (!this.filters.typeInProgress) {
          this.filteredJobs = reject(this.filteredJobs, {
            status: JobStatus.IN_PROGRESS
          }) as Job[];
        }
        if (!this.filters.typeNew) {
          this.filteredJobs = reject(this.filteredJobs, {
            status: JobStatus.NEW
          }) as Job[];
        }
        if (!this.filters.typeCompleted) {
          this.filteredJobs = reject(this.filteredJobs, {
            status: JobStatus.COMPLETED
          }) as Job[];
        }
      }
      // check if search query
      if (this.filters.searchQuery) {
        this.filteredJobs = this.filteredJobs?.filter((job) => {
          // todo -
          return job.title
            ?.toLowerCase()
            .includes(this.filters.searchQuery!.toLowerCase());
        });
      }
      // If a sorting method is selected
      if (this.filters.sort) {
        if (this.filters.sort === "ascending") {
          this.filteredJobs = orderBy(
            this.filteredJobs,
            ["startDate"],
            ["asc"]
          ) as Job[];
        } else {
          this.filteredJobs = orderBy(
            this.filteredJobs,
            ["startDate"],
            ["desc"]
          ) as Job[];
        }
      }
    },
    showDetail(payload: Job) {
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: payload.id
        }
      });
    },
    removeSelection() {
      this.selected = undefined;
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: undefined
        }
      });
    },
    addJob() {
      const request = {
        title: this.$data.addJobDetail.title,
        description: this.$data.addJobDetail.description,
        postcode: this.$data.addJobDetail.postcode,
        startDate: this.$data.addJobDetail.startDate,
        endDate: this.$data.addJobDetail.endDate,
        status: this.$data.addJobDetail.status,
        assignee: this.$data.addJobDetail.assignee
      };
      api
        .post("/jobs", request)
        .then((res) => {
          notification.success("Job added");
          this.addJobToggle = false;
          this.addJobDetail = {
            title: undefined,
            description: undefined,
            postcode: undefined,
            startDate: undefined,
            endDate: undefined,
            status: JobStatus.NEW,
            assignee: undefined
          };
          this.getJobs();
        })
        .catch((err) => {
          notification.error(err.message);
        });
    },
    getJobs() {
      api
        .get("/jobs")
        .then((res) => {
          this.jobs = res.data;
          this.filterJobs();
          if (this.$route.query.id) {
            this.selected = find(res.data, {
              id: this.$route.query.id
            });
          }
        })
        .catch((err) => {
          notification.error(err.message);
        });
    }
  }
});
