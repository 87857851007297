
import Vue from "vue";
import { Job } from "@/shared/models";
import { DateTime } from "luxon";
import api from "@/services/dataService";
import notification from "@/services/notificationService";
export default Vue.extend({
  name: "JobsRightSidebar",
  props: {
    jobId: String
  },
  data() {
    return {
      DateTime: DateTime,
      editJobToggle: false,

      jobDetail: undefined as undefined | Job,
      editJobDetail: {
        title: undefined as undefined | string,
        description: undefined as undefined | string,
        postcode: undefined as undefined | string,
        startDate: undefined as undefined | Date,
        endDate: undefined as undefined | Date,
        status: undefined as undefined | string,
        assignee: undefined as undefined | string
      }
    };
  },
  created() {
    this.getJobDetail();
  },

  watch: {
    jobId: function() {
      if (!this.jobId) {
        this.jobDetail = undefined;
      } else {
        // watching jobId value to update jobDetail on value change
        this.getJobDetail();
      }
    }
  },
  methods: {
    getJobDetail() {
      if (this.$route.query.id) {
        api
          .get("/jobs/" + this.$route.query.id)
          .then(res => {
            this.jobDetail = res.data;
            this.editJobDetail = {
              title: res.data.title,
              description: res.data.description,
              postcode: res.data.postcode,
              startDate: new Date(res.data.startDate),
              endDate: new Date(res.data.endDate),
              status: res.data.status,
              assignee: res.data.assignee
            };
          })
          .catch(err => {
            notification.error(err.message);
          });
      }
    },
    updateJob(id: string) {
      api
        .put("/jobs/" + id, this.editJobDetail)
        .then(res => {
          // Emit event job-update to trigger fetch jobs on Jobs.vue view
          // to refresh the list after update.
          this.$emit("job-update");
          this.editJobToggle = false;
          // Get updated job details to display on sidebar
          this.getJobDetail();
          notification.success("Job updated");
        })
        .catch(err => {
          notification.error(err.message);
        });
    }
  }
});
